const initialState = {
  authError: '',
  authVerifySms: false,
  authVerifySmsError:false,
  smsSentAgain:false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "NOTIFY_VERIFY_SMS":
      return { ...state, authVerifySms: true };
    case "RESET_LOGIN":
      return { ...state, authVerifySms: false, authVerifySmsError:false, authError:'', smsSentAgain:false};
    case "SMS_SENT_AGAIN":
      return { ...state, smsSentAgain:true};
    case "NOTIFY_VALIDATION_SMS_ERROR":
      return { ...state, authVerifySmsError: action.payload.authError };
    case "NOTIFY_VALIDATION_AUTH_ERROR":
      return { ...state, authError: action.payload.authError };
    default:
      return state
  }
};

export default notificationReducer
