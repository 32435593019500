import {disableIntercom} from "@components/custom/intercom";

export function getClientId(user) {
    const modeClientIds = JSON.parse(localStorage.getItem('cmode', false));
    const user_client_id = user.client ? user.client.id : null;
    if(modeClientIds){
        disableIntercom()
    }
    return !modeClientIds ? user_client_id : modeClientIds.clientId;
}
export function getDataForSaving(data) {
  let finalData = {};

  for (let field in data) {
    switch (field) {
      case 'membership_id':
        finalData[field] = data[field]?.value;
        break;
      case 'bank_id':
        finalData[field] = data[field]?.value;
        break;
      case 'jurisdiction_id':
        finalData[field] = data[field]?.value;
        break;
      case 'parent_id':
        finalData[field] = data[field]?.value;
        break;
      case 'manager_id':
        finalData[field] = data[field]?.value;
        break;
      case 'country_id':
        finalData[field] = data[field]?.value;
        break;
      case 'category_id':
        finalData[field] = data[field]?.value;
        break;
      case 'disabled_target_countries': {
        finalData[field] = Array.isArray(data[field]) ? data[field].map((item) => item.value) : [];
        break;
      }
      case 'additional_categories': {
        finalData[field] = Array.isArray(data[field]) ? data[field].map((item) => item.value) : [];
        break;
      }
      default : {
        finalData[field] = data[field];
        break;
      }
    }
  }
  return finalData;
}
export function getNormalData(data) {
  let
    client = data,
    newObj = {
      id: null,
      parent_id: '',
      slug: '',
      name: '',
      country_id: '',
      bank_id: '',
      jurisdiction_id: '',
      manager_id: '',
      membership_id: '',
      country: null,
      category_id: '',
      category: null,
      city: '',
      postal_code: '',
      website_url: '',
      address_1: '',
      address_2: '',
      vat: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      disabled_target_countries: '',
      country_targeting: [],
      additional_categories: [],
      budget: '1500.00',
      trial_credit:'1500.00',
      click_rate: 6,
      notes: '',
    };

  for (let item in client) {
    let selectedOption = {
      label: '',
      value: ''
    };

    if (item === 'country_id') {
      if (!client['country']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['country'].id;
        selectedOption.label = client['country'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'category_id') {
      if (!client['category']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['category'].id;
        selectedOption.label = client['category'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'membership_id') {
      if (!client['membership']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['membership'].id;
        selectedOption.label = client['membership'].description;
      }
      newObj[item] = selectedOption;
    } else if (item === 'parent_id') {
      if (!client['parent']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['parent'].id;
        selectedOption.label = client['parent'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'bank_id') {
      if (!client['bank']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['bank'].id;
        selectedOption.label = client['bank'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'jurisdiction_id') {
      if (!client['jurisdiction']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['jurisdiction'].id;
        selectedOption.label = client['jurisdiction'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'manager_id') {
      if (!client['manager']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['manager'].id;
        selectedOption.label = client['manager'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'disabled_target_countries') {
      let dtc = [];
      let disabled_target_countries = [];
      if (client[item]) {
        for (let i of client[item]) {
          dtc.push({
            label: i.name,
            value: i.id
          })
          disabled_target_countries.push(i.id)
        }
      }
      newObj['disabled_target_countries'] = dtc;
      newObj['country_targeting'] = dtc;
    } else if (item === 'additional_categories') {
      let dtc = [];
      let additional_categories = [];
      if (client[item]) {
        for (let i of client[item]) {
          dtc.push({
            label: i.name,
            value: i.id
          })
          additional_categories.push(i.id)
        }
      }
      newObj['additional_categories'] = dtc;
    } else if (item === 'notifications' && client['notifications'].length > 0 && client['notifications'][0].hasOwnProperty('id')) {
      let ns = [];
      if (client[item]) {
        for (let i of client[item]) {
          ns.push(i.id.toString())
        }
      }
      newObj['notifications'] = ns;
    } else if (item === 'parent_id') {
      if (!client[item]) {
        client[item] = '';
      } else {
        newObj['parent_id'] = client[item];
      }
    } else {
      newObj[item] = client[item]
    }

  }
  return newObj;
}
export function getDataForSavingAccount(data) {
  let finalData = {};

  for (let field in data) {
    switch (field) {
      case 'membership_id':
        finalData[field] = data[field]?.value;
        break;
      case 'bank_id':
        finalData[field] = data[field]?.value;
        break;
      case 'jurisdiction_id':
        finalData[field] = data[field]?.value;
        break;
      case 'manager_id':
        finalData[field] = data[field]?.value;
        break;
      case 'country_id':
        finalData[field] = data[field]?.value;
        break;
      case 'category_id':
        finalData[field] = data[field]?.value;
        break;
      case 'disabled_target_countries': {
        finalData[field] = Array.isArray(data[field]) ? data[field].map((item) => item.value) : [];
        break;
      }
      case 'additional_categories': {
        finalData[field] = Array.isArray(data[field]) ? data[field].map((item) => item.value) : [];
        break;
      }
      default : {
        finalData[field] = data[field];
        break;
      }
    }
  }
  return finalData;
}
export function getNormalDataAccount(data) {
  let
    client = data,
    newObj = {
      id: null,
      slug: '',
      name: '',
      country_id: '',
      bank_id: '',
      jurisdiction_id: '',
      manager_id: '',
      membership_id: '',
      country: null,
      category_id: '',
      category: null,
      city: '',
      postal_code: '',
      website_url: '',
      address_1: '',
      address_2: '',
      vat: '',
      contact_name: '',
      account_email: '',
      contact_phone: '',
      disabled_target_countries: '',
      country_targeting: [],
      additional_categories: [],
      budget: '1500.00',
      trial_credit:'1500.00',
      click_rate: 6,
      notes: '',
    };

  for (let item in client) {
    let selectedOption = {
      label: '',
      value: ''
    };

    if (item === 'country_id') {
      if (!client['country']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['country'].id;
        selectedOption.label = client['country'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'category_id') {
      if (!client['category']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['category'].id;
        selectedOption.label = client['category'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'membership_id') {
      if (!client['membership']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['membership'].id;
        selectedOption.label = client['membership'].description;
      }
      newObj[item] = selectedOption;
    } else if (item === 'bank_id') {
      if (!client['bank']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['bank'].id;
        selectedOption.label = client['bank'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'jurisdiction_id') {
      if (!client['jurisdiction']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['jurisdiction'].id;
        selectedOption.label = client['jurisdiction'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'manager_id') {
      if (!client['manager']) {
        selectedOption = null;
      } else {
        selectedOption.value = client['manager'].id;
        selectedOption.label = client['manager'].name;
      }
      newObj[item] = selectedOption;
    } else if (item === 'disabled_target_countries') {
      let dtc = [];
      let disabled_target_countries = [];
      if (client[item]) {
        for (let i of client[item]) {
          dtc.push({
            label: i.name,
            value: i.id
          })
          disabled_target_countries.push(i.id)
        }
      }
      newObj['disabled_target_countries'] = dtc;
      newObj['country_targeting'] = dtc;
    } else if (item === 'additional_categories') {
      let dtc = [];
      let additional_categories = [];
      if (client[item]) {
        for (let i of client[item]) {
          dtc.push({
            label: i.name,
            value: i.id
          })
          additional_categories.push(i.id)
        }
      }
      newObj['additional_categories'] = dtc;
    } else if (item === 'notifications' && client['notifications'].length > 0 && client['notifications'][0].hasOwnProperty('id')) {
      let ns = [];
      if (client[item]) {
        for (let i of client[item]) {
          ns.push(i.id.toString())
        }
      }
      newObj['notifications'] = ns;

    } else {
      newObj[item] = client[item]
    }

  }
  return newObj;
}
