const initialState = {
    cid: null,
    clientName: null,
    isCmode: false
};

const adminSessionReducer = (state = initialState, action) => {

    switch (action.type) {
        case "ADMIN_SESSION_SETTINGS":

            if(action.clientId) {
                localStorage.setItem('cmode', JSON.stringify({clientId:action.clientId, clientName:action.clientName}));
            } else {
                localStorage.removeItem('cmode');
            }

            return { cid: action.clientId, clientName: action.clientName, isCmode: action.clientId !== null };
        default:
            let cid2 = JSON.parse(localStorage.getItem('cmode', null));
            return { cid: cid2, isCmode: cid2 !== null };
    }
};

export default adminSessionReducer
