import { combineReducers } from "redux"
import notificationReducer from "./notification/"
import emailReducer from "./email/"
import chatReducer from "./chat/"
import todoReducer from "./todo/"
import customizer from "./customizer/"
import auth from "./auth/"
import categoryReducer from "./category";
import clientReducer from "./client";
import countryReducer from "./countries";
import adminReportReducer from "./adminReport";
import adminFinancialReport from "./adminFinancialReport";
import adminSettingsReducer from "./adminSettings";
import adminEBooksReducer from "./adminEBooks";
import adminInvociesReducer from "./adminInvoices";
import adminLiveClicksReducer from "./adminLiveClicks";
import clientEBooksReducer from "./clientEBooks";
import clientReportReducer from "./clientReport";
import adminSessionReducer from "./adminSession";
import adminEmailLogsReducer from "./adminEmailLogs";
import adminApiLogsReducer from "./adminApiLogs";
import authorizedUserReducer from "./user";
import membershipReducer from "./membership";
import bankReducer from "./bank";
import productReducer from "./product";
import widgetReducer from "./widget";
import auditReducer from "./audit";
import jurisdictionReducer from "./jurisdiction";
import roleReducer from "./role";

const rootReducer = combineReducers({
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  adminReports: adminReportReducer,
  adminFinancialReport: adminFinancialReport,
  adminSettings: adminSettingsReducer,
  adminInvoices: adminInvociesReducer,
  adminEBooks: adminEBooksReducer,
  clientEBooks: clientEBooksReducer,
  adminSession: adminSessionReducer,
  clientReport: clientReportReducer,
  notification: notificationReducer,
  authorizedUser: authorizedUserReducer,
  category: categoryReducer,
  countries: countryReducer,
  client: clientReducer,
  liveClicks: adminLiveClicksReducer,
  emailLogs: adminEmailLogsReducer,
  apiLogs: adminApiLogsReducer,
  membership:membershipReducer,
  bank:bankReducer,
  product:productReducer,
  widget:widgetReducer,
  audit:auditReducer,
  jurisdiction:jurisdictionReducer,
  role:roleReducer
});

export default rootReducer
