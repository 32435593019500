import {toast} from "react-toastify";
const initialState = {
    invoices: [],
    invoice: {},
    list:[],
    listTotal:0,
    unpaidFinished:0,
    invoiceSaved:0,
    archive_total_amount:0
};

const adminInvoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "INVOICE_APPROVED":
            if(action.payment && action.payment.status_id === 1){
                toast.success('Invoice successfully approved and paid on stripe!')
            } else if(action.payment && action.payment.status_id === 2){
                toast.error('Invoice approved but wasn\'t paid on stripe, reason: '+action.payment.error_reason)
            }
            return { ...state, invoices: action.invoices };
        case "FETCH_INVOICES":
            return { ...state, invoices: action.invoices };
        case "FETCH_INVOICE":
            return { ...state, invoice: action.invoice };
        case "INVOICE_UNPAID":
            return { ...state, unpaidFinished: state.unpaidFinished+1 };
        case "NEW_INVOICE":
            toast.success('Invoice successfully created!')
            let updatedInvoicesWithNew = state.invoices;
            updatedInvoicesWithNew.push(action.invoice)
            return { ...state, invoices: updatedInvoicesWithNew, invoiceSaved: state.invoiceSaved+1};
        case "FETCH_INVOICES_ARCHIVE_LIST":
            return { ...state, list: action.list, listTotal: action.listTotal};
        case "FETCH_INVOICES_ARCHIVE_TOTAL":
            return { ...state, archive_total_amount: action.amount};
        case "UPDATE_INVOICE":
            let updatedInvoices = state.invoices.map(invoice => {
                if (invoice.id === action.invoice.id) {
                    return action.invoice
                }
                return invoice
            });
            toast.success('Invoice successfully updated!')

            return { ...state, invoices: updatedInvoices, invoiceSaved: state.invoiceSaved+1};
        default:
            return state
    }
};

export default adminInvoicesReducer
