import {toast} from "react-toastify";
const initialState = {
    eBooks: [],
    eBook:{},
    dates:{},
    eBookLeads:[]
};

const eBooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_EBOOKS":
            return { ...state, eBooks: action.eBooks, dates:action.dates};
        case "FETCH_EBOOK_ADDED":
            toast.success('eBook created!')
            return { ...state, eBooks: action.eBooks };
        case "FETCH_EBOOK_UPDATE":
            toast.success('eBook updated!')
            return { ...state, eBooks: action.eBooks };
        case "FETCH_EBOOK_DELETE":
            toast.success('eBook updated!')
            return { ...state, eBooks: action.eBooks };
        case "FETCH_EBOOK":
            return { ...state, eBook: action.eBook };
        case "FETCH_EBOOK_LEADS":
            return { ...state, eBookLeads: action.eBookLeads };
        case "FETCH_EBOOK_LEADS_DELETE":
            return { ...state, eBookLeads: action.eBookLeads };
        default:
            return state
    }
};

export default eBooksReducer
