import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import axios from "axios"
import * as Icon from "react-feather"
import {connect} from "react-redux";
import { history } from "@src/history"
import { logoutWithJWT }  from '@store/actions/auth/loginActions';
import { STRIPE_PK } from '@configs/constants';

import "./custom.scss";
import {loadStripe} from "@stripe/stripe-js";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path)
}

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      { props.user.role.id !== 2 ? (
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => handleNavigation(e, "/settings")}
        >
          <Icon.User size={14} className="mr-50" />
          <span className="align-middle">Edit Profile</span>
        </DropdownItem>

        ) : null }

      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => {
          e.preventDefault();
          props.logoutWithJWT();
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {

  redirectToCheckoutPage = () => {
    const clientId = this.props.user.client.id;

    axios.post(`/api/v1/memberships/switch/from/trial`, { clientId })
      .then(response => {
        loadStripe(STRIPE_PK)
          .then(stripe => {
            stripe.redirectToCheckout({
              sessionId: response.data.data.session_id
            }).then(function (result) {
              if (result.error) {
                //history.push('/error/500');
              }
            });
          });
      }).catch(err => {
      //history.push('/error/500')
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        {this.props.user.membership && this.props.user.membership.id === 3 && (
          <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
          >
            <DropdownToggle tag="a" className="nav-link position-relative">
              <span>credit: {this.props.user.membership.trial_credit}</span>
              <span className="badge badge-info ml-1">{this.props.user.membership.name}</span>
            </DropdownToggle>
            <DropdownMenu
              tag="ul"
              right
              className={`dropdown-menu-media dropdown-cart`}
            >
            <li className={`dropdown-menu-header d-block`}>
                <div className="dropdown-header m-0">
                  <h3 className="white">
                    Improve
                  </h3>
                  <span className="notification-title">Your Current Plan</span>
                </div>
              </li>

              <li
                className={`dropdown-menu-footer border-top d-block`}
              >
                <div
                  className="dropdown-item p-1 text-center text-primary"
                  onClick={() => {this.redirectToCheckoutPage()}}
                >
                  <Icon.CreditCard size={15} />
                  <span className="align-middle text-bold-600 ml-50">
                    Register Credit Card
                  </span>
                </div>
              </li>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}




        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.user.name}
              </span>
              <span className="user-status">{''}</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.user.photoUrl ? this.props.user.photoUrl : '/user-icon.png' }
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.authorizedUser.user
  }
}

export default connect(mapStateToProps, {logoutWithJWT})(NavbarUser)
