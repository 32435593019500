import {toast} from "react-toastify";

const initialState = {
    clients: [],
    clients_paginate:[],
    clients_paginate_total:0,
    selectedClient: null,
    client: {
        id: -1,
        click_rate: 0
    },
    notifications: []
};


const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CLIENTS":
      return {...state, clients: action.clients};
    case "CLIENT_SEND_PASSWORD_EMAIL":
      toast.success(`Client email was sent`)
      return {...state};
    case "GET_CLIENTS_PAGINATE":
      return {...state, clients_paginate: action.clients, clients_paginate_total:action.total};
    case "ADD_CLIENT":
      state.clients.push(action.client);
      toast.success(`Client "${action.client.name}" successfully added`)
      return {...state};
    case "ADD_ACCOUNT":
      state.clients.push(action.client);
      toast.success(`Client "${action.client.name}" successfully added`)
      return {...state};
    case "GET_CLIENT":
      return {...state, client: action.client};
    case "DELETE_CLIENT":
      toast.success('The client was successfully deleted!')
      return {...state};
    case "UPDATE_SELECTED_CLIENT":
      toast.success('The changes have been saved')
      return {...state, client: action.client};
    case "UPDATE_CLIENT":
      toast.success('The changes have been saved')
      return {...state, client: action.client};
    case "UPDATE_CLIENT_ERROR":
      toast.error(action.error)
      return state;
    case "GET_NOTIFICATIONS":
      return {...state, notifications: action.notifications};
    case "GET_BILLING_HISTORY":
      return {...state, billing: action.billing};
    case "GET_PAYMENT_METHODS":
      return {...state, payments: action.payments};
    case "ADD_PAYMENT_METHOD":
      state.payments.push(action.payment);
      toast.success(`Payment Method was successfully created`)
      return {...state};
    case "DELETE_PAYMENT_METHOD":
      toast.success('Payment Method was successfully deleted!')
      return {...state, payments: action.payments};
    case "SAVE_INVOICES_ZIP":
      toast.success('Invoices File saved!')
      return {...state, client: action.client};
    case "GET_INVOICES_ZIP":
        const url = window.URL.createObjectURL(new Blob([action.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', action.invocies_zip);
        document.body.appendChild(link);
        link.click();
      return state;
    default:
      return state
  }
};

export default clientReducer
