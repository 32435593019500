import {toast} from "react-toastify";
const initialState = {
    categoryWidgets: [],
    clientWidgets: [],
    allClients: []
};

const widgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CATEGORY_WIDGETS":
            return { ...state, categoryWidgets: action.widgets};
        case "FETCH_CLIENT_WIDGETS":
            return { ...state, clientWidgets:action.widgets };
        case "FETCH_CLIENT_WP_NAME":
            return { ...state, clientWpName:action.wp_name };
        case "FETCH_ALL_WIDGETS":
            return { ...state, categoryWidgets:action.categoryWidgets, clientWidgets:action.clientWidgets, allClients:action.allClients };
        case "UPDATE_CLIENT_WIDGET":
            toast.success('Widget updated!')
            return { ...state, clientWidgets:action.widgets };
        case "UPDATE_CLIENT_WIDGET_STATUS":
            toast.success('Widget status updated!')
            return state;
        case "ADMIN_UPDATE_CLIENT_WIDGET":
            toast.success('Widget updated!')
            return state
        default:
            return state
    }
};

export default widgetReducer
