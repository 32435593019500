import axios from "axios"

export const getClients = (params) => {
    return dispatch => {
        axios
            .get(`/api/v1/clients`, {params:params})
            .then(response => {
                dispatch({
                    type: "GET_CLIENTS",
                    clients: response.data.data,
                })
            })
            .catch(err => console.log(err))
    }
};
export const getClientsPaginate = (params) => {
    return dispatch => {
        axios
            .get(`/api/v1/clients/paginate`, {
                params: params
            })
            .then(response => {
                dispatch({
                    type: "GET_CLIENTS_PAGINATE",
                    clients: response.data.data,
                    total: response.data.meta.total,
                })
            })
            .catch(err => console.log(err))
    }
};
export const getClient = (id) => {
    return dispatch => {
        axios
            .get(`/api/v1/clients/${id}`)
            .then(response => {
                dispatch({
                    type: "GET_CLIENT",
                    client: response.data.data,
                })
            })
            .catch(err => console.log(err))
    }
};
export const loginUnderClient = (id, callback = ()=>{}) => {
    return dispatch => {
        axios
            .post(`/api/v1/clients/${id}/login-under-client`)
            .then(response => {
                dispatch({
                    type: "LOGIN_UNDER_CLIENT"
                })
              callback();
            })
            .catch(err => console.log(err))
    }
};
export const deleteClient = (id, callback = ()=>{}) => {
    return dispatch => {
        axios
            .delete(`/api/v1/clients/${id}`)
            .then(response => {
                dispatch({
                    type: "DELETE_CLIENT",
                    // client: id,
                })
              callback();
            })
            .catch(err => console.log(err))
    }
};

export const addClient = (data, callback = ()=>{}) => {
    return dispatch => {
        axios
            .post(`/api/v1/clients`, data)
            .then(response => {
              console.log(response)
                dispatch({
                    type: "ADD_CLIENT",
                    client: response.data.data,
                })
                callback();
            })
            .catch(err => {
              console.log(['err', err])
              if(err.response.status === 422 &&
                err.response.data.errors && err.response.data.errors.contact_email){
                dispatch({
                  type: "UPDATE_CLIENT_ERROR",
                  error: err.response.data.errors.contact_email[0],
                })
                callback();
              }
            })
    }
};

export const editClient = (id, data, callback = ()=>{}) => {
    return dispatch => {
        axios
            .put(`/api/v1/clients/${id}`, data)
            .then(response => {
                dispatch({
                    type: "UPDATE_CLIENT",
                    client: response.data.data,
                })
                callback();
                return response.data
            })
            .catch(err => {
                console.log(['err', err])
                if(err.response.status === 422 &&
                    err.response.data.errors && err.response.data.errors.contact_email){
                    dispatch({
                        type: "UPDATE_CLIENT_ERROR",
                        error: err.response.data.errors.contact_email[0],
                    })
                    callback();
                }
            })
    }
};

export const addAccount = (id, data, callback = ()=>{}) => {
  return dispatch => {
    axios
    .post(`/api/v1/clients/${id}/account`, data)
    .then(response => {
      console.log(response)
      dispatch({
        type: "ADD_ACCOUNT",
        client: response.data.data,
      })
      callback();
    })
    .catch(err => console.log(err))
  }
};

export const editAccount = (id, data, callback = ()=>{}) => {
  return dispatch => {
    axios
    .put(`/api/v1/clients/${id}`, data)
    .then(response => {
      dispatch({
        type: "UPDATE_CLIENT",
        client: response.data.data,
      })
      callback();
      return response.data
    })
    .catch(err => {
      console.log(['err', err])
      if(err.response.status === 422 &&
        err.response.data.errors && err.response.data.errors.contact_email){
        dispatch({
          type: "UPDATE_CLIENT_ERROR",
          error: err.response.data.errors.contact_email[0],
        })
        callback();
      }
    })
  }
};

export const editClientClickRate = (id, clickRate) => {
    return dispatch => {
        axios
            .put(`/api/v1/clients/${id}/click-rates`, {click_rate: clickRate})
            .then(response => {
                dispatch({
                    type: "UPDATE_SELECTED_CLIENT",
                    client: response.data.data,
                })
            })
            .catch(err => console.log(err))
    }
};

export const getNotifications = () => {
  return dispatch => {
    axios
      .get(`/api/v1/notifications`)
      .then(response => {
        dispatch({
          type: "GET_NOTIFICATIONS",
          notifications: response.data.data,
        })
      })
      .catch(err => console.log(err))
  }
};

export const getBillingHistory = (id) => {
  return dispatch => {
    axios
      .get(`/api/v1/clients/${id}/payments`)
      .then(response => {
        dispatch({
          type: "GET_BILLING_HISTORY",
          billing: response.data.data,
        })
      })
      .catch(err => console.log(err))
  }
};

export const getPaymentMethods = (id) => {
    return dispatch => {
        axios
            .get(`/api/v1/clients/${id}/payment-methods`)
            .then(response => {
                dispatch({
                    type: "GET_PAYMENT_METHODS",
                    payments: response.data.data,
                })
            })
            .catch(err => console.log(err))
    }
};

export const addPaymentMethod = (client, data, callback = ()=>{}) => {
  return dispatch => {
    axios
      .post(`/api/v1/clients/${client}/payment-methods`, data)
      .then(response => {
        dispatch({
          type: "ADD_PAYMENT_METHOD",
          payment: response.data.data,
        });

        dispatch(getPaymentMethods(client));

        if(callback){
          callback()
        }
      })
      .catch(err => console.log(err))
  }
};

export const deletePaymentMethod = (data) => {
  return dispatch => {
    axios
      .delete(`/api/v1/clients/${data.client.id}/payment-methods/${data.slug}`)
      .then(response => {
        dispatch({
            type: "DELETE_PAYMENT_METHOD",
            payments: response.data.data
        })
      })
      .catch(err => console.log(err))
  }
};

export const pauseClient = (id) => {
    return dispatch => {
        axios
            .put(`/api/v1/clients/${id}/pause`)
            .then(response => {
              dispatch({
                    type: "GET_CLIENTS",
                    clients: response.data.data,
                })

                return response.data
            })
            .catch(err => console.log(err))
    }
};

export const unpauseClient = (id) => {
    return dispatch => {
        axios
            .put(`/api/v1/clients/${id}/unpause`)
            .then(response => {
              dispatch({
                    type: "GET_CLIENTS",
                    clients: response.data.data,
                })

                return response.data
            })
            .catch(err => console.log(err))
    }
};

export const sendPasswordEmail = (id) => {
    return dispatch => {
        axios
            .post(`/api/v1/clients/${id}/send_password_email`)
            .then(response => {
              dispatch({
                    type: "CLIENT_SEND_PASSWORD_EMAIL"
                })

                return response.data
            })
            .catch(err => console.log(err))
    }
};

export const saveInvoicesZip = (client_id, file_data, ext) => {
  return dispatch => {
    axios
      .put(`/api/v1/clients/${client_id}/invoices_zip`, {file_data:file_data, ext:ext})
      .then(response => {
        dispatch({
            type: "SAVE_INVOICES_ZIP",
            client: response.data.data,
        })
      })
      .catch(err => console.log(err))
  }
};

export const getInvoicesZip = (client_id, invocies_zip) => {
  return dispatch => {
    axios.get(`/api/v1/clients/${client_id}/invoices_zip`, {
        responseType: 'blob'
    }).then((response) => {
        dispatch({
            type: "GET_INVOICES_ZIP",
            data: response.data,
            invocies_zip: invocies_zip,
        })
    }).catch(err => console.log(err))
  }
};
