const initialState = {
    total_revenue: {
        total:0,
        total_previous:0
    },
    category_chart_data:[],
    clients_chart_data:[],
    product_chart_data:[],
    category_revenue_data:[],
    client_revenue_data:[],
    product_revenue_data:[],
    download_report_data:[],
    total_revenue_chart_data:{
        data:[],
        data_prev:[],
        labels:[]
    },
};

const adminFinancialReport = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PRODUCT_CHART_DATA":
            return { ...state, product_chart_data: action.data};
        case "FETCH_CLIENT_CHART_DATA":
            return { ...state, clients_chart_data: action.data};
        case "FETCH_CATEGORY_CHART_DATA":
            return { ...state, category_chart_data: action.data};
        case "FETCH_CATEGORY_REVENUE":
            return { ...state, category_revenue_data: action.data};
        case "FETCH_CLIENT_REVENUE":
            return { ...state, client_revenue_data: action.data};
        case "FETCH_PRODUCT_REVENUE":
            return { ...state, product_revenue_data: action.data};
        case "FETCH_TOTAL_REVENUE_CHART":
            return { ...state, total_revenue_chart_data: action.data};
        case "FETCH_TOTAL_REVENUE":
            return { ...state, total_revenue: action.totals};
        case "FETCH_GET_FINANCIAL_REPORT_DATA":
            return { ...state, download_report_data: action.data};
        case "FETCH_REFERRER_REVENUE":
            return { ...state, referrer_revenue_data: action.data};
        case "FETCH_EFFECTIVE_CPC":
            return { ...state, effective_cpc_data: action.data};
        case "FETCH_EVENTS_OS":
            return { ...state, os_list: action.data};
        default:
            return state
    }
};

export default adminFinancialReport
