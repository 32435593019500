const initialState = {
    profit: {
        clicks: 0,
        current_rate: 0,
        profit: 0
    },
    clicks: [],
    clickRates: [],
    clickCountries: []
};

const clientReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CLIENT_PROFIT":
            return { ...state, profit: action.data };
        case "FETCH_CLIENT_CLICKS":
            return { ...state, clicks: action.data };
        case "FETCH_CLIENT_CLICK_RATES":
            return { ...state, clickRates: action.data };
        case "FETCH_CLIENT_COUNTRIES_CLICKS":
            return { ...state, clickCountries: action.data };
        case "DOWNLOAD_RATES_PDF":
            const url = window.URL.createObjectURL(new Blob([action.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `clicks-statement-${action.startedAt}--${action.finishedAt}.pdf`); 
            document.body.appendChild(link);
            link.click();
          return state;
        default:
            return state
    }
};

export default clientReportReducer
