import React from "react";
import {Button} from "reactstrap";
import {history} from "@src/history";
import {connect} from "react-redux";
import {adminSessionSettings} from "@store/actions/adminSession";

class BackToAdminButton extends React.PureComponent {

    render() {
        if (this.props.session.isCmode && (this.props.user.role.name === "superAdmin" || this.props.user.role.name === "adminManager")) {
            return <div className="logged_in_as_box">
                <Button onClick={() => {
                    this.setState({client: null});
                    this.props.adminSessionSettings(null);
                    history.push("/admin/clients-and-ads")
                }}>
                    <span>Logged in as <b>{this.props.session.cid.clientName}</b>. Back To Admin</span>
                </Button>
            </div>
        }

        return '';
    }
}

const mapStateToProps = state => {
    return {
        session: state.adminSession,
        user: state.authorizedUser.user
    }
};

export default connect(mapStateToProps, {adminSessionSettings})(BackToAdminButton)
