import {toast} from "react-toastify";
      
const initialState = {
    disabledCountries: [],
    disabledIpList: [],
    adminUsers: [],
    adminUser:null,
    adminUserSaved:0,
    limits:{}
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_ADMIN_USER_SAVED":
            toast.success(`Admin User saved`)
            return { ...state, adminUserSaved: state.adminUserSaved+1 };
        case "FETCH_ADMIN_USER":
            return { ...state, adminUser: action.adminUser };
        case "FETCH_ADMIN_USERS":
            return { ...state, adminUsers: action.adminUsers };
        case "FETCH_DISABLED_IP_LIST":
            return { ...state, disabledIpList: action.disabledIpList };
        case "FETCH_ADMIN_GET_LIMITS":
            return { ...state, limits: action.limits };
        case "FETCH_ADMIN_LIMITS_UPDATED":
            toast.success(`Limits updated`)
            return { ...state, limits: action.limits };
        case "FETCH_DISABLED_COUNTRIES":
            return { ...state, disabledCountries: action.disabledCountries };
        case "FETCH_DISABLED_COUNTRIES_UPDATE":
            toast.success(`Countries updated`)
            return { ...state, disabledCountries: action.disabledCountries };
        default:
            return state
    }
};

export default categoryReducer
