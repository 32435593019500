import {toast} from "react-toastify";
const initialState = {
    clicksList: [],
    clicksListTotal:0
};

const eBooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CLICK_DELETED":
            toast.success('Click event successfully deleted!')
            return state;
        case "FETCH_LIVE_CLICKS_LIST":
            return { ...state, clicksList: action.clicksList, clicksListTotal: action.clicksListTotal};
        default:
            return state
    }
};

export default eBooksReducer
