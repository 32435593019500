const initialState = {
    list: []
};

const bankReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PRODUCTS_LIST":
            return { ...state, list: action.list };
        default:
            return state
    }
};

export default bankReducer
