import React from "react";
import {history} from "@src/history";
import {connect} from "react-redux";
import {adminSessionSettings} from "@store/actions/adminSession";
import {getClient, loginUnderClient,} from "@store/actions/client";
import Select from "react-select";


export class SwitchAccount extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      is_cmod: this.props.session.isCmode,
      client: this.props.client,
    }
  }

  componentDidMount() {
    this.setState({
      is_account: !!this.props.client.parent_id,
      has_accounts: !!(this.props.client.accounts && this.props.client.accounts.length),
      current: this.setCurrent(),
      accounts: this.setAccounts(),
    })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.client.id !== this.props.client.id) {
      this.setState({
        is_cmod: this.props.session.isCmode,
        client: this.props.client,
        is_account: !!this.props.client.parent_id,
        has_accounts: !!(this.props.client.accounts && this.props.client.accounts.length),
        current: this.setCurrent(),
        accounts: this.setAccounts()
      })
    }
  }

  setCurrent() {
    let current = null;
    if(this.props.client && this.props.client.id > 0) {
      let label = this.props.client.accounts && this.props.client.accounts.length ?
        'All' : `${this.props.client.category.name} (${this.props.client.name})`;
      current = {value: this.props.client.id, label: label}
    }
    return current
  }

  setAccounts() {
    let accounts = null;
    if(this.props.client && this.props.client.accounts && this.props.client.accounts.length) {
      accounts = this.props.client.accounts.map((v) => {
        return {value: v.id, label: `${v.category.name} (${v.name})`}
      })
    } else if (this.props.session.isCmode && this.props.client.parent) {
      accounts = this.props.client.parent.accounts.map((v) => {
        return {value: v.id, label: `${v.category.name} (${v.name})`}
      })
      accounts = accounts.filter((item) => item.value != this.props.client.id)
      accounts.push({value: this.props.client.parent.id, label: 'All'})
    }
    return accounts
  }

  formatOptionLabel = ({ value, label, customAbbreviation }) => {
    return this.props.client.parent_id == value ? <div><b>{label}</b></div> : <div>{label}</div>
  };

  render() {
    return (
      <React.Fragment>
        {/*{this.state && this.state.accounts && (this.state.current && this.state.has_accounts || (this.state.is_cmod && this.state.is_account)) ?*/}
        {/*{this.state.is_cmod && (this.state.has_accounts || this.state.is_account) ?*/}
        {/*{(this.state.is_cmod || this.props.user.role.name == 'clientManager') && (this.state.has_accounts || this.state.is_account) ?*/}
        {/*если админ переключлся на парента или чайлда */}
        {/*если клиент парент и имеет чайлдов */}
        {/*если клиент парент и переключился на чайлда */}
        {(this.props.user.role.name != 'clientManager' && (this.state.has_accounts || this.state.is_account) && this.state.is_cmod)
        || (this.props.user.role.name == 'clientManager' && this.state.accounts)
        || (this.props.user.role.name == 'clientManager' && this.state.accounts && this.state.is_cmod)
          ?
          <div className="switch-account-wrapper">
            <Select
              className="switch-account"
              classNamePrefix="select"
              // formatOptionLabel={this.formatOptionLabel}
              value={this.state.current}
              name="switch_account"
              options={this.state.accounts}
              onChange={(e) => {
                this.props.adminSessionSettings(e.value, e.label);
                this.props.loginUnderClient(e.value, () => {
                  window.open(history.createHref({pathname: this.props.location.pathname}), "_self")
                })
              }}
            />
          </div> : null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    session: state.adminSession,
    user: state.authorizedUser.user,
    client: state.client.client,
  }
};

export default connect(mapStateToProps, {adminSessionSettings, getClient, loginUnderClient})(SwitchAccount)
