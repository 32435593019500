const initialState = {
    clients: [],
    clicks: 0,
    payments: [],
    paymentsFailed: [],
    fun_numbers:{
        bid_client_name:'',
        bid_amount:0,
        budget_client_name:'',
        budget_amount:0,
        best_day:'',
        best_day_amount:0,
        best_month:'',
        best_month_amount:0,
    },
    profit: {
        total: 0,
        byCategory: [],
        byGrowth: [],
        byCategoryChartData: [],
        totalsChartData: [],
        prevsChartData: [],
        prevs4ChartData: [],
        diff_days: 0,
        byClient: []
    },
    gettingData:false
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_FUN_NUMBERS":
            return { ...state, fun_numbers: action.fun_numbers };
        case "FETCH_CLIENTS":
            return { ...state, clients: action.clients };
        case "FETCH_CLIENTS_COUNT":
            return { ...state, clients_count: action.count };
        case "FETCH_CLICKS":
            return { ...state, clicks: action.clicks };
        case 'FETCH_CLICK_PROFIT':
            state.profit.total = action.total;
            return { ...state };
        case 'FETCH_CLICK_PROFIT_BY_CATEGORY':
            state.profit.byCategory = action.data;
            return { ...state };
        case 'FETCH_CLICK_PROFIT_BY_GROWTH':
            state.profit.byGrowth = action.data;
            return { ...state };
        case 'FETCH_CLICK_TOTALS_CHART_DATA_BY_CATEGORY_START':
            return { ...state, gettingData:true };
        case 'FETCH_CLICK_TOTALS_CHART_DATA_BY_CATEGORY':
            let totalsChartData = {data:{}, name:'Totals'};
            let prevsChartData = {data:{}, name:'Previous'};
            let prevs4ChartData = {data:{}, name:'4 weeks Avg'};
            action.data.forEach((category, key) =>{
                Object.keys(category.data).forEach((date, i) => {
                    totalsChartData.data[date] = totalsChartData.data[date] ||
                        {date:category.data[date].date, profit:0, clicks:0};
                    totalsChartData.data[date].profit += parseFloat(category.data[date].profit)
                    totalsChartData.data[date].clicks+= parseInt(category.data[date].clicks)

                    prevsChartData.data[date] = prevsChartData.data[date] ||
                        {date:category.data[date].date, profit:0, clicks:0};
                    prevsChartData.data[date].profit += parseFloat(category.data[date].prev_profit)
                    prevsChartData.data[date].clicks += parseInt(category.data[date].prev_clicks)

                    prevs4ChartData.data[date] = prevs4ChartData.data[date] ||
                        {date:category.data[date].date, profit:0, clicks:0};
                    prevs4ChartData.data[date].profit += parseFloat(category.data[date].prev_4_profit)
                    prevs4ChartData.data[date].clicks += parseInt(category.data[date].prev_4_clicks)
                })
            })
            state.profit.totalsChartData = [totalsChartData];
            state.profit.prevsChartData = [prevsChartData];
            state.profit.prevs4ChartData = [prevs4ChartData];
            state.profit.diff_days = action.diff_days;
            state.gettingData = false;
            return { ...state };
        case 'FETCH_CLICK_PROFIT_CHART_DATA_BY_CATEGORY':
            state.profit.byCategoryChartData = action.data;
            return { ...state };
        case 'FETCH_CLICK_PROFIT_BY_CLIENT':
            state.profit.byClient = action.data;
            return { ...state };
        case 'FETCH_PAYMENTS':
            return { ...state, payments: action.data };
        case 'FETCH_PAYMENTS_FAILED':
            return { ...state, paymentsFailed: action.data };
        default:
            return state
    }
};

export default categoryReducer
