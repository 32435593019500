import {toast} from "react-toastify";
const initialState = {
    list: [],
    listTotal:0
};

const adminEmailLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_RESENT":
            toast.success('Email resent!')
            return state;
        case "FETCH_LIST":
            return { ...state, list: action.list, listTotal: action.listTotal};
        default:
            return state
    }
};

export default adminEmailLogsReducer
