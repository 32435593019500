const initialState = {
    list: []
};

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_ROLES_LIST":
            return { ...state, list: action.list };
        default:
            return state
    }
};

export default roleReducer
