const initialState = {
    list: []
};

const jurisdictionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_JURISDICTIONS_LIST":
            return { ...state, list: action.list };
        default:
            return state
    }
};

export default jurisdictionReducer
