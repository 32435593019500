import { history } from "@src/history"
import axios from "axios"
import { apiErrorHandler }  from '@utils/apiErrorHandler';
import {getClient} from "../client";
import {getClientId} from "@utils/clientMode";



export const loginWithJWTReset = user => {
  return dispatch => {
    dispatch({
        type: "RESET_LOGIN"
    });
  }
};

export const sendSmsAgain = user => {
  return dispatch => {
    axios.post("/api/v1/users/send-sms-again", {
      email: user.email,
      password: user.password
    })
    .then(response => {
        dispatch({
          type: "SMS_SENT_AGAIN"
        });
    })
    .catch(err => {
        dispatch(apiErrorHandler(err));
    });
  }
};
export const loginWithJWT = user => {
  return dispatch => {
    axios
      .post("/api/v1/users/login", {
        email: user.email,
        password: user.password,
        sms_code: user.sms_code,
        skip_sms_code: user.skip_sms_code
      })
      .then(response => {

        if (response.data) {
          let user = response.data.data;

          dispatch({
            type: "FETCH_AUTHORIZED_USER_INFO",
            user: user
          });

          if (user) {
            dispatch(getClient(getClientId(user)));
          }

          localStorage.removeItem('cmode');
          let redirect = '/dashboard'
          if(user.role.name === 'superAdmin' || user.role.name === 'adminAccountant'){
              redirect = "/admin/dashboard";
          }else if(user.role.name === 'adminManager'){
              redirect = "/admin/clients-and-ads";
          }
          history.push(redirect)
        }
      })
      .catch(err => {
        dispatch(apiErrorHandler(err));
      });
  }
};

export const logoutWithJWT = () => {
  return dispatch => {
      axios
          .post("/api/v1/users/logout", {})
          .then(response => {
              localStorage.removeItem('cmode');
              history.push('/')
          })
          .catch(err => {
            dispatch(apiErrorHandler(err));
          });
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export const resetPassword = email => {
  return dispatch => {
    axios
      .post("/api/v1/password/reset/send", {
        email: email,
      })
      .then(response => {
        if (response.data) {
          dispatch({
            type: "RESET_PASSWORD",
            reset: response.data.data
          });
        }
      })
      .catch(err => {
        dispatch(apiErrorHandler(err));
      });
  }
}
