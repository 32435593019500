import {toast} from "react-toastify";
const initialState = {
    eBooks:[],
    chartData:[],
    totals:{
        avg_cost: 0,
        downloads: 0,
        spend: 0
    }
};

const eBooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CLIENT_EBOOKS":
            return { ...state, eBooks: action.eBooks.map((eBook, key) => {
                eBook.leads = [];
                return eBook;
            })};
        case "DELETE_EBOOK_LEAD":
            toast.success('eBook lead deleted!')
            return state;
        case "FETCH_CLIENT_EBOOK_BUDGET_UPDATE":
            toast.success('eBook budget updated!')
            return state;
        case "FETCH_CLIENT_EBOOK_TOTALS":
            return { ...state, totals: action.totals};
        case "FETCH_CLIENT_EBOOK_REPORT":
            let eBooks = state.eBooks;
            let chartData = [];
            if(action.ebook_id > 0){
                eBooks = state.eBooks.map((eBook, key) => {
                    if(eBook.id === action.ebook_id){
                        eBook.leads = [];
                        action.eBookReport.forEach((report, key2) => {
                            eBook.leads.push(report);
                        })
                    }
                    chartData.push({
                        name:eBook.name,
                        leads:eBook.leads
                    })
                    return eBook;
                })
            }
            return { ...state, eBooks: eBooks, chartData: chartData };
        default:
            return state
    }
};

export default eBooksReducer
