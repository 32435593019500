import {toast} from "react-toastify";

const initialState = {
    categories: [],
    categoriesClients: [],
    category: null,
    selectedCategory: null,
    selectedCategoryMaxRate: -1,
    selectedCategoryMinRate: -1,
    ctypes:[],
    managersList:[],
    categoriesWithManagers:[]
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CATEGORIES":
            return { ...state, categories: action.categories };
        case "FETCH_CATEGORIES_WITH_MANAGERS":
            return { ...state, categoriesWithManagers: action.categories };
        case "FETCH_CATEGORIES_CLIETNS":
            return { ...state, categoriesClients: action.categories };
        case "FETCH_MANAGERS_LIST":
            return { ...state, managersList: action.managers };
        case "FETCH_CATEGORY":
            return { ...state, category: action.category };
        case "FETCH_CATEGORIES_TYPES":
            return { ...state, ctypes: action.ctypes };
        case "FETCH_CATEGORY_RATES":
            return { ...state, selectedCategoryMaxRate: action.data.category_max_rate, selectedCategoryMinRate: action.data.category_min_rate };
        case "ADD_CATEGORY":
            state.categories.push(action.category);
          toast.success(`Category "${action.category.name}" successfully created!`)

          return { ...state };
        case "UPDATE_CATEGORY":
            let updatedCategories = state.categories.map(category => {
                if (category.id === action.category.id) {
                    return action.category
                }
                return category
            });
          toast.success('Category successfully updated!')

          return { ...state, categories: updatedCategories };
        default:
            return state
    }
};

export default categoryReducer
