const initialState = {
  audit_paginate: [],
  audit_paginate_total: 0,
  audit_actions: []
};


const auditReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_AUDIT_PAGINATE":
      return {...state, audit_paginate: action.data, audit_paginate_total: action.total};
    case "GET_AUDIT_ACTIONS":
      return {...state, audit_actions: action.data};
    default:
      return state
  }
};

export default auditReducer
