const initialState = {
    list: [],
    listTotal:0
};

const adminEmailLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_API_LOGS_LIST":
            return { ...state, list: action.list, listTotal: action.listTotal};
        default:
            return state
    }
};

export default adminEmailLogsReducer
