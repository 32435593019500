const initialState = {
    list: [],
    switch_from_trial_complete:false,
    switch_from_trial_error:false
};

const membershipReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_MEMBERSHIP_SWITCH_FROM_TRIAL_COMPLETE":
            
            console.log(['action', action])
            return { ...state, switch_from_trial_complete: action.switched, switch_from_trial_error:action.error };
        case "FETCH_MEMBERSHIP_LIST":
            return { ...state, list: action.list };
        default:
            return state
    }
};

export default membershipReducer
